import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container, {
  ImageContainer,
  AgeButton,
  ButtonContainer,
  hrefLink,
  hrefImgLink,
  FooterBlock
} from "../components/pageComponents";
import kitchenPic from "../images/kitchen-white.jpg";

const IndexPage = () => (
  <Layout>
    <SEO
      title="New Program Gives Homeowners Up To $100,000 To Upgrade Their Homes."
      keywords={[`savings`, `homeowners`, `government programs`]}
    />
    <Container>
      <h1>This Program Allows Homeowners To Remodel Their Homes With No Money Out Of Pocket.</h1>
      <a href={hrefLink} target="_blank" rel="noopener noreferrer" className="textLink">
        <h1>Find Out If You Qualify.</h1>
      </a>
      <hr />
      <p className="strong jackpot">Middle-Class Homeowners are about to hit the jackpot.</p>
      <hr />
      <ImageContainer>
        <a href={hrefImgLink} target="_blank" rel="noopener noreferrer">
          <img src={kitchenPic} alt="Kitchen" />
        </a>
      </ImageContainer>
      <p>
        Millions of middle-class Americans are sitting on a gold mine and they don't even know it. After almost a decade
        of steady increases in home values, more than 90% of homeowners have positive equity with over{" "}
        <span className="strong">14 million Americans being considered "equity rich".</span>
      </p>

      <p>
        Just in the last year alone, American homeowners have added{" "}
        <span className="strong">over $1 TRILLION dollars in wealth</span> due to their home equity, yet most of them
        don't know how to use that equity to their advantage. It's their money...why not claim it?
      </p>

      <p>
        As more Americans learn about this amazing program, intelligent homeowners are starting to use this strategy to{" "}
        <a href={hrefLink} target="_blank" rel="noopener noreferrer" className="textLink">
          claim THOUSANDS{" "}
        </a>
        to use towards home improvements, paying off debt, college educations for their children, or even just taking a
        dream vacation.
      </p>
      <h4>What's the Catch?</h4>
      <p>
        Not surprisingly, a lot of people don't believe that this program can possibly be real. They think it's "too
        good to be true" or that it only works for homeowners with poor credit. The reality is that this program was
        designed specifically for average American homeowners and having good or average credit actually helps you
        qualify for even more money!
      </p>

      <p>
        Most homeowners use this cash for home renovations and upgrades, which increases the value of the house, making
        this a brilliant investment. However, you are free to use this money however you'd like.{" "}
        <a href={hrefLink} target="_blank" rel="noopener noreferrer" className="textLink">
          See If You're Eligible>>
        </a>
      </p>
      <h4>How To Check If I Qualify?</h4>
      <p>
        It is completely free to see if you qualify and can be done in as little as{" "}
        <span className="strong">2 minutes.</span>
      </p>
      <h3>Choose your age range below to get started...</h3>
      <ButtonContainer>
        <AgeButton link={hrefLink}>18-25</AgeButton>
        <AgeButton link={hrefLink}>26-35</AgeButton>
        <AgeButton link={hrefLink}>36-45</AgeButton>
        <AgeButton link={hrefLink}>46-55</AgeButton>
        <AgeButton link={hrefLink}>56-64</AgeButton>
        <AgeButton link={hrefLink}>65 & up</AgeButton>
      </ButtonContainer>
    </Container>
    <FooterBlock>
      <Container>
        <sup>1</sup> - http://www.fanniemae.com/resources/file/aboutus/media/HARP-Research-Report-030613.pdf
        <p className="center">
          THIS IS AN ADVERTISEMENT AND NOT AN ACTUAL NEWS ARTICLE, BLOG OR CONSUMER PROTECTION UPDATE. The authors of
          this website are dedicated to bringing readers valuable information which can help them accomplish their
          financial goals. This site does receive compensation for product reviews and referrals or purchases made
          through our links. This page is an advertisement/advertorial. Any story that may appear on this site is for
          demonstration purposes only and everyone's results may vary. We hope you find our online resource informative
          and helpful.
        </p>
      </Container>
    </FooterBlock>
  </Layout>
);

export default IndexPage;
